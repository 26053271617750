/*
 * @Author: Tiger
 * @Date: 2020-07-10 06:30:33
 * @LastEditTime: 2022-09-10 19:32:14
 * @LastEditors: qiufh qiufh@bocspace.cn
 * @Description: About Us
 * @FilePath: /official_website/src/pages/contact.js
 */ 
import React, { useState } from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import { Input, message } from 'antd';
import Layout from '../components/layout'
import JournalStore from '../store/journal/JournalStore'

const { TextArea } = Input;

const ContactPage = () => {
  const [emailInfo, setEmailInfo] = useState({
    text11: '',
    text12: '',
    text1: '',
    text2: '',
    text3: '',
    text4: '',
    text5: ''
  });

  const [productList, setProductList] = useState([]);
  
  const chooseProduct = (type) =>{
    let list = JSON.parse(JSON.stringify(productList));
    let index = list.indexOf(type);
    console.log('chooseProduct', index)
    if(index == -1) {
      list.push(type);
      console.log('chooseProductpush', list)
      setProductList(list);
    }else {
      list.splice(index, 1);
      console.log('chooseProductsplice', list)
      setProductList(list);
    }
  }
  
  const inputChange = (type, e) =>{
    emailInfo[type] = e.target.value;
    setEmailInfo(emailInfo);
  }

  const submit = () =>{
    if(!emailInfo.text11) {
      message.warn('Please enter last name');
      return;
    }else if(!emailInfo.text12) {
      message.warn('Please enter first name');
      return;
    }else if(!emailInfo.text2) {
      message.warn('Please enter contact phone');
      return;
    }else if(!emailInfo.text3) {
      message.warn('Please enter contact email');
      return;
    }else if(productList.length == 0) {
      message.warn('Please choose project type');
      return;
    }

    let store = new JournalStore();
    store.sendOfficialEmail({
			emailInfo: {
        text1: emailInfo.text12 + ' ' + emailInfo.text11,
        text2: emailInfo.text2,
        text3: emailInfo.text3,
        text4: productList.join(','),
        text5: emailInfo.text5
      }
		}).then((res)=> {
      console.log('submit', res)
      if(res) {
        message.success('Submitted successfully');
      }
    })
  }

  return (
    <Layout>
      <Container className="contact-info-page" fluid >
        <Row>
          <Col xs={6}></Col>
          <Col xs={6} className="contact-right">
          <div className="contact-intro">
          <div className="bocspace-slogan">
            <div class="white-banner"></div>
            <div class="bocspace-label">Contact Us</div>
            <p class="bocspace-desc">Bocspace Technology Co., Ltd.</p>
            <p class="bocspace-desc"><img src="/contact/icon793.png" alt="" />
              &nbsp;&nbsp;Huashengtiancheng, building 23, East <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;District, No.10 Yuandong Road, northwest,<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Haidian District, Beijing,China 
            </p>
            <p class="bocspace-desc"><img src="/contact/icon793.png" alt="" />
              &nbsp;&nbsp;No. 888 Yanling East Road, Jiangyin, Wuxi,<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Jiangsu, China
            </p>
            <p class="bocspace-desc"><img src="/contact/icon793.png" alt="" />
              &nbsp;&nbsp;Glodon building,Weiyang District,<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Xi'an City, Shaanxi Province,China
            </p>
            <p class="bocspace-desc"><img src="/contact/icon793.png" alt="" />
              &nbsp;&nbsp;Room 508-A, No. 2, Lane 666, Shenhong <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Road, Minhang District, Shanghai,China
            </p>
            <p class="bocspace-desc"><img src="/contact/icon793.png" alt="" />
              &nbsp;&nbsp;18th FL, Silom Complex Buidling, 191 Silom <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Road, Silom, Bangkok, Thailand, 10500
            </p>
            <p style={{paddingTop:"5px"}} class="bocspace-desc"><img src="/contact/icon794.png" alt="" />
              &nbsp;&nbsp;+86 189 2120 6895</p>
            <p class="bocspace-desctel"><img src="/contact/icon795.png" alt="" />
              &nbsp;&nbsp;mua@bocspace.cn  </p>
            <p style={{paddingTop:"2px"}} class="bocspace-desc"><img src="/contact/icon794.png" alt="" />
              &nbsp;&nbsp;+86 189 2120 0191</p>
            <p class="bocspace-desctel"><img src="/contact/icon795.png" alt="" />
              &nbsp;&nbsp;market@bocspace.cn </p>
          </div>
          </div>
          </Col>
        </Row>
        <div className="contact-us-page" >
        <div className="contact-us-title">
          Please Contact Us If You Want to Know More!
        </div>
        <div className="contact-us-sub-title">
          Just fill in and send it off. We will contact you immediately.
        </div>
        <div className="contact-us-content">
          <div>
            <div className="contact-us-right-item">
              <Input placeholder="Last name*" onChange={(e) => {inputChange('text11', e)}} />
              <Input placeholder="First name*" onChange={(e) => {inputChange('text12', e)}} />
            </div>
            <div className="contact-us-right-item">
              <Input placeholder="Contact phone* (+Country code)" onChange={(e) => {inputChange('text2', e)}} />
              <Input placeholder="Contact email*" onChange={(e) => {inputChange('text3', e)}} />
            </div>
            <div className="contact-us-right-item">
              <TextArea rows={4} placeholder="Your message(Optional)" onChange={(e) => {inputChange('text5', e)}} />
            </div>
            <div className="contact-us-right-item">
              <div className={productList.indexOf('Camp') == -1 ? 
                "contact-us-btn mr first":"contact-us-btn mr first checked"} 
                onClick={() => chooseProduct('Camp')}>Camp</div>
              <div className={productList.indexOf('Residential') == -1 ? 
                "contact-us-btn":"contact-us-btn checked"}
                onClick={() => chooseProduct('Residential')}>Residential</div>
            </div>
            <div className="contact-us-right-item">
              <div className={productList.indexOf('Emergency') == -1 ? 
                "contact-us-btn mr first":"contact-us-btn mr first checked"} 
                onClick={() => chooseProduct('Emergency')}>Emergency</div>
              <div className={productList.indexOf('Commercial') == -1 ? 
                "contact-us-btn":"contact-us-btn checked"}
                onClick={() => chooseProduct('Commercial')}>Commercial</div>
            </div>
            <div className="contact-us-right-item">
              <div className="contact-us-submit" onClick={() => submit()}>submit</div>
            </div>
          </div>
        </div>
      </div>
      </Container>
    </Layout>

  )
}

export default ContactPage